.bsd-layout {
  background-image: url("./../../images/Background.jpg");

  .bsd-content {
    position: relative;
    min-height: calc(100vh - #{$flow-header-height});

    @include responsive($width-large) {
      padding: (base-spacing(3)) 0 (base-spacing(2));
    }
  }
}
