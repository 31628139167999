.bsd-page-not-found {
  @include content-styles;

  &-content,
  &-text,
  &-heading,
  &-subheading {
    color: $white;
  }
}
