.bsd-general-error {
  @include content-styles;

  .bsd-icon-circle-alert {
    margin: 0 auto;
  }

  &-content {
    margin-top: base-spacing(2);
  }

  &-heading,
  &-content,
  &-text {
    color: $white;
    text-align: center;
  }
}
