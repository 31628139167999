// Global styles
html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: $white-alabaster;
}

* {
  &,
  &::before,
  &::after {
    box-sizing: inherit;
  }
}
