.bsd-account-not-eligible {
  @include content-styles;

  &-content,
  &-text,
  &-heading,
  &-subheading {
    color: $white;
  }

  &-subheading {
    @include responsive($width-large) {
      padding: 0 ($base-spacing * 8) 0 ($base-spacing * 8);
    }
  }

  &-button {
    margin-top: base-spacing(1);
  }
}
