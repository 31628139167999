@mixin content-styles {
  width: 100%;
  padding: base-spacing(3) $base-spacing base-spacing(2);
  color: $white;
  text-align: center;

  @include responsive($width-medium) {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 0 $base-spacing;
    transform: translate(-50%, -50%);
  }
}
