.bsd-flow-header {
  @include print {
    display: none;
  }
  width: 100%;
  height: $flow-header-height;
  background-color: $gray-cod;

  &-container {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 $base-spacing;

    @include responsive($width-large) {
      padding: base-spacing(2);
    }
  }

  &-logo {
    position: relative;
    margin-right: base-spacing(0.75);
    padding-right: base-spacing(0.75);
    color: $white;

    &:hover,
    &:focus {
      color: $white;
    }

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 1px;
      background: $white;
      content: "";
    }
  }

  &-heading {
    margin-top: base-spacing(0.5);
    color: $white;
    font-size: $heading-4;
  }

  .bsd-icon-bsp-logo {
    width: 100px;
    height: 36px;
    fill: $white;
  }
}
